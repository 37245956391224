import React, { useContext } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
// Own Components
import QRCodeSecondStep from './components/QRCodeSecondStep';
// Components
import QRCodeReader from '~/components/qrcode/reader';
// Standalone Components
import { Container, FloatButton, Icon, Text } from './style';

export default function Float() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);
  return (
    <Container>
      <QRCodeReader
        Trigger={
          <FloatButton backgroundcolor={theme.color.primary}>
            <Icon opacity={isMobile ? '1' : '0.5'} />
            <Text>Ler QR Code</Text>
          </FloatButton>
        }
        SecondStepComponent={QRCodeSecondStep}
        title={'Realizar uma operação com QR Code'}
        description={
          'Para realizar uma transferência, passe sobre um QR Code SocialMe válido'
        }
        kind={[
          'ACCOUNT_VALUE',
          'ACCOUNT',
          'PERSONAL',
          'PRODUCT_SERVICE',
          'DONATION_PRODUCT_SERVICE',
        ]}
        secondStepParameters={{}}
      />
    </Container>
  );
}
