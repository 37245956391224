import React from 'react';
// Libraries
import { Route, Switch } from 'react-router-dom';
// Pages
import Accounts from './pages/Accounts';
import SecurityLayer from './pages/SecurityLayer';

export default function Start() {
  return (
    <Switch>
      <Route exact path={`/`} component={Accounts} />
      <Route path={`/:accountNumber`} component={SecurityLayer} />
    </Switch>
  );
}
