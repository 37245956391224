import React from 'react';
// Libraries
import {
  DateAndTimeCell,
  PrimaryAndSecondaryTextCell,
} from '~/components/table/cells';
// Own components
import QRCodeDrawerContent from './components/QRCodeDrawerContent';
// Components
import Table from '~/components/table';
import Button from '~/components/button';
import Drawer from '~/components/drawer';
// Utils
import {
  WalletFormatConversion,
  CapitalizeString,
} from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function Content({ data }) {
  const tableColumns = [
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: 'Benefício recebido',
      dataIndex: 'donation_product_service_name',
      key: 'donation_product_service_name',
      render: (donation_product_service_name, record) => (
        <PrimaryAndSecondaryTextCell
          primaryText={CapitalizeString(donation_product_service_name)}
          secondaryText={CapitalizeString(
            record.giver_person_social_name ||
              record.giver_person_name ||
              'Anônimo'
          )}
        />
      ),
    },
    {
      title: 'Valor recebido',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 24, marginRight: 10 }}
            src={PickMeCoinIcon()}
            alt="..."
          />
          {WalletFormatConversion(amount)}
        </div>
      ),
    },
    {
      title: 'Resgate',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Drawer
          Trigger={<Button type="dashed">Imprimir QR Code</Button>}
          title="Impressão do QR Code do benefício"
          DrawerContentComponent={QRCodeDrawerContent}
          DrawerContentComponentParams={record}
          bodyStyle={{ padding: 0 }}
        />
      ),
    },
    /*
    {
      title: 'Description',
      dataIndex: 'kind',
      key: 'kind',
      render: (kind, record) => PrepareDescriptionField(kind, record),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      render: (value) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 24, marginRight: 10 }}
            src={PickMeCoinIcon()}
            alt="..."
          />
          {WalletFormatConversion(value)}
        </div>
      ),
    },
    */
  ];
  return (
    <div>
      <Table dataReceived={data} tableColumns={tableColumns} />
    </div>
  );
}
