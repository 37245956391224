import React, { useContext, useState } from 'react';
// Libraries
import { Form, message, Alert } from 'antd';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Components
import Text from '~/components/typography/text';
import Input from '~/components/input';
import Button from '~/components/button';
import FormItem from '~/components/form/FormItem';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function AuthAccountInactive() {
  const { WalletLogin, walletAccount } = useContext(WalletAccountContext);
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);

  async function CreateFirstPassword(newPassword) {
    setLoadingButton(true);
    console.log('🚀 API: Creating first account password');
    const params = {
      operation: ['Wallet', 'Accounts', 'ChangePassword'],
      urlParams: {
        agencyNumber: walletAccount.agency_number,
        accountNumber: walletAccount.account_number + walletAccount.account_vd,
      },
      data: {
        newPassword: newPassword,
      },
    };
    const response = await SocialMeAPI(params);
    console.log('  ⚡ API Response: ', response);
    WalletLogin(newPassword);
    if (response.success) {
      message.success('Senha criada com sucesso!');
      WalletLogin(newPassword);
      window.location.reload();
    } else if (!response.success) {
      message.error('Senha em formato inválido');
    } else {
      message.error('Alguma coisa aconteceu...');
    }
    setLoadingButton(false);
  }

  const onFinish = (data) => {
    CreateFirstPassword(data.password);
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      size="small"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Alert
        message={
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexDirection: 'column',
            }}
          >
            <span>
              <b>Regras para criar uma senha:</b>
            </span>
            <span>- Somente 4 dígitos</span>
            <span>- Somente números</span>
            <span>- Não ter uma sequência crescente (Ex: 1234)</span>
            <span>- Não ter uma sequência decrescente (Ex: 4321)</span>
          </div>
        }
        type="warning"
      />
      <FormItem
        label={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Text strong>Senha</Text>
          </div>
        }
        name={['password']}
        item={<Input type="password" maxLength={4} />}
        rules={[
          {
            required: true,
            message: 'Uma senha é obrigátoria',
          },
        ]}
      />
      <FormItem
        label={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Text strong>Confirme sua senha</Text>
          </div>
        }
        name={['password_confirm']}
        item={<Input type="password" maxLength={4} />}
        rules={[
          {
            required: true,
            message: 'Confirme a sua senha',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('As senhas devem ser iguais');
            },
          }),
        ]}
      />
      <Button
        style={{ width: '100%', marginTop: 20 }}
        type="primary"
        size="large"
        loading={loadingButton}
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              // console.log('Validate Failed:', info);
            });
        }}
      >
        Criar senha
      </Button>
    </Form>
  );
}
