import React from 'react';
// Libraries
import {
  DateAndTimeCell,
  ImageWithPrimaryAndSecondaryTextCell,
} from '~/components/table/cells';
// Components
import Table from '~/components/table';
import Button from '~/components/button';
// Utils
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function Content({ data }) {
  const tableColumns = [
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: 'Recebido de',
      dataIndex: 'people',
      key: 'people',
      render: (people, record) => (
        <ImageWithPrimaryAndSecondaryTextCell
        image={record.image}
          primaryText={people}
          secondaryText={'Endereço'}
        />
      ),
    },
    {
      title: 'Valor recebido',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 24, marginRight: 10 }}
            src={PickMeCoinIcon()}
            alt="..."
          />
          {amount}
        </div>
      ),
    },
    {
      title: 'Detalhes',
      dataIndex: 'hash',
      key: 'hash',
      render: (hash) => (
        <Button>{hash}</Button>
      ),
    },
    /*
    {
      title: 'Description',
      dataIndex: 'kind',
      key: 'kind',
      render: (kind, record) => PrepareDescriptionField(kind, record),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      render: (value) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 24, marginRight: 10 }}
            src={PickMeCoinIcon()}
            alt="..."
          />
          {WalletFormatConversion(value)}
        </div>
      ),
    },
    */
  ];
  return (
    <div>
      <Table dataReceived={data} tableColumns={tableColumns} />
    </div>
  );
}
