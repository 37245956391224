import styled from "styled-components";

import { Table } from "antd";

const MainTable = styled(Table)`
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #340348;
    border-radius: 20px;
  }

  th.ant-table-cell {
    background-color: ${(props) => props.headerColor};
    border: none;
  }

  td.ant-table-cell {
    border: none;
  }
`;

export default MainTable;
