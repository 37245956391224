import React from 'react';
import ReactToPrint from 'react-to-print';
import Text from '~/components/typography/text';
import Button from '~/components/button';
import QRCode from 'qrcode.react';
import { GetBrandIcon } from '~/utils/SocialMeAssets';
import {
  WalletFormatConversion,
  CapitalizeString,
} from '~/utils/Transformations';
import {
  HourInBrazilianFormat,
  DateInBrazilianFormatWithAddDays,
} from '~/utils/DateAndTime';

/* 
Paper Size
width: 792,
height: 1120,
*/

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div>
        <div style={{ width: 380 }}>
          <div
            style={{
              width: 380,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 20,
            }}
          >
            <img
              src={GetBrandIcon('socialMeVariation4')}
              style={{
                width: 100,
              }}
              alt="..."
            />
          </div>
          <div
            style={{
              width: 380,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <QRCode
              value={this.props.QRCode._id}
              size={300}
              bgColor="#ffffff"
              fgColor="#000"
              level="L"
              includeMargin={false}
              renderAs="svg"
            />
          </div>
          <div
            style={{
              width: 380,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginBottom: 10,
            }}
          >
            <Text strong style={{ fontSize: '18pt' }}>
              {`${WalletFormatConversion(this.props.QRCode.amount.SME)} Mes`}
            </Text>
          </div>
          <div
            style={{
              width: 380,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginBottom: 10,
            }}
          >
            <Text style={{ fontSize: '14pt' }}>Proprietário</Text>
            <Text strong style={{ fontSize: '16pt' }}>
              {CapitalizeString(
                this.props.QRCode.person_social_name ||
                  this.props.QRCode.person_name
              )}
            </Text>
          </div>
          <div
            style={{
              width: 380,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Text style={{ fontSize: '12pt' }}>Validade</Text>
            <Text strong style={{ fontSize: '14pt' }}>
              {`${DateInBrazilianFormatWithAddDays(
                this.props.QRCode.created_at,
                1
              )} às ${HourInBrazilianFormat(this.props.QRCode.created_at)}`}
            </Text>
          </div>
        </div>
      </div>
    );
  }
}

class Example extends React.Component {
  render() {
    console.log('this.props.QRCode -> ', this.props.QRCode);
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <div
              style={{
                padding: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button type="primary" style={{ marginBottom: 10 }}>
                Imprimir
              </Button>
            </div>
          )}
          content={() => this.componentRef}
        />
        <ComponentToPrint
          ref={(el) => (this.componentRef = el)}
          QRCode={this.props.QRCode}
        />
      </div>
    );
  }
}

export default Example;
