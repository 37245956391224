import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { ArrowRightOutlined } from '@ant-design/icons';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import AddProductService from './components/AddProductService';
import SelectProductService from './components/SelectProductService';
import ChosenOfferingContainer from './components/ChosenOfferingContainer';
// Components
import Button from '~/components/button';
import Card from '~/components/card';
import Divider from '~/components/divider';
import Loading from '~/components/loading';
import Text from '~/components/typography/text';
import Space from '~/components/space';
// Standalone Components
import { Container } from './style';
// Utils
import {
  CapitalizeString,
  WalletFormatConversion,
} from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function DONATION_PRODUCT_SERVICE({ QRCodeInformation }) {
  const { theme } = useContext(ThemeContext);
  const [offerings, setOfferings] = useState();
  const [selectedOffering, setSelectedOffering] = useState();
  const [chosenOffering, setChosenOffering] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  const PSDModel = () => {
    return (
      <div>
        <Card>
          <Space>
            <Text>Produto solicitado: </Text>
            <Text strong>
              {CapitalizeString(QRCodeInformation.psd_model_name)}
            </Text>
          </Space>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Text>Valor máximo do pedido: </Text>
            <Text strong style={{ margin: '0px 10px' }}>
              {WalletFormatConversion(QRCodeInformation.amount.SME)}
            </Text>
            <img
              style={{ width: 16 }}
              src={PickMeCoinIcon()}
              alt="Social Me Coin"
            />
          </div>
        </Card>
      </div>
    );
  };

  useEffect(() => {
    async function GetOfferings() {
      const params = {
        operation: ['Partner', 'Product', 'Get'],
        data: {
          filter: {
            //kind: 'PRODUCT',
            model: QRCodeInformation.psd_model_id,
          },
        },
      };
      const response = await SocialMeAPI(params);
      console.log('  ⚡ API Response (GetOfferings): ', response);
      if (response.success) {
        setOfferings(response.body.psOfferings.elements);
      }
      setLoadingContent(false);
    }
    GetOfferings();
  }, [QRCodeInformation]);

  return (
    <Container backgroundcolor={theme.color.backgroundSecondaryLight}>
      {loadingContent ? (
        <Loading />
      ) : (
        <>
          <PSDModel />
          {chosenOffering ? (
            <ChosenOfferingContainer
              chosenOffering={chosenOffering}
              setChosenOffering={setChosenOffering}
              setSelectedOffering={setSelectedOffering}
              QRCodeInformation={QRCodeInformation}
            />
          ) : (
            <>
              <Divider>Selecione um produto para venda</Divider>
              <AddProductService />
              <SelectProductService
                offerings={offerings}
                selectedOffering={selectedOffering}
                setSelectedOffering={setSelectedOffering}
              />
              <Button
                disabled={selectedOffering ? false : true}
                type="primary"
                icon={<ArrowRightOutlined />}
                size="large"
                style={{ width: '100%' }}
                onClick={() => setChosenOffering(selectedOffering)}
              >
                Próximo
              </Button>
            </>
          )}
        </>
      )}
    </Container>
  );
}
