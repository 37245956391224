import { format, addMinutes, differenceInDays, addDays } from 'date-fns';

let debug = false;

export const HourInBrazilianFormat = (date) => {
  return format(new Date(date), 'HH:mm');
};

export const DateInBrazilianFormat = (date) => {
  if (debug) console.log('date -> ', date);
  var d = new Date();
  var n = d.getTimezoneOffset();
  const newDate = addMinutes(new Date(date), n * 2);
  if (debug) console.log('newDate -> ', newDate);

  // data: troque por "new Date()" para a data atual
  /*
    let d = new Date(date);
    if(debug) console.log('d -> ', d)
    // formatar data no formato ISO 8601
    var iso = d.getFullYear().toString() + "-";
    iso += d.getMonth().toString().padStart(2, '0') + "-";
    iso += d.getDate().toString().padStart(2, '0') + "T";
    iso += d.getHours().toString().padStart(2, '0') + ":";
    iso += d.getMinutes().toString().padStart(2, '0') + ":";
    iso += d.getSeconds().toString().padStart(2, '0');
    if(debug) console.log('iso -> ', iso); // 2018-10-17T14:22:29
    */
  return format(new Date(newDate), 'dd/MM/yyyy');
};

export const DateInBrazilianFormatWithAddDays = (date, days) => {
  var d = new Date();
  var n = d.getTimezoneOffset();
  const newDate = addMinutes(new Date(date), n * 2);
  let dateWithDays = addDays(new Date(newDate), days);

  // data: troque por "new Date()" para a data atual
  /*
    let d = new Date(date);
    if(debug) console.log('d -> ', d)
    // formatar data no formato ISO 8601
    var iso = d.getFullYear().toString() + "-";
    iso += d.getMonth().toString().padStart(2, '0') + "-";
    iso += d.getDate().toString().padStart(2, '0') + "T";
    iso += d.getHours().toString().padStart(2, '0') + ":";
    iso += d.getMinutes().toString().padStart(2, '0') + ":";
    iso += d.getSeconds().toString().padStart(2, '0');
    if(debug) console.log('iso -> ', iso); // 2018-10-17T14:22:29
    */
  return format(new Date(dateWithDays), 'dd/MM/yyyy');
};

export const DiferenceBetweenTheCurrentDate = (date) => {
  const currentDate = new Date();

  return differenceInDays(currentDate, new Date(date));
};
