export function CapitalizeString(value) {
  if(!value) return
  const stringLowerCase = value.toLowerCase();
  let array = stringLowerCase.split(' ');
  let newArray = [];

  for (var index = 0; index < array.length; index++) {
    if (array[index].length <= 2) {
      if (array[index].length === 1) {
        newArray.push(array[index].charAt(0) + array[index].slice(1));
      }
      if (array[index].length === 2) {
        if (array[index].split('')[0] === 'd') {
          newArray.push(array[index].charAt(0) + array[index].slice(1));
        } else {
          newArray.push(
            array[index].charAt(0).toUpperCase() + array[index].slice(1)
          );
        }
      }
    } else {
      newArray.push(
        array[index].charAt(0).toUpperCase() + array[index].slice(1)
      );
    }
  }

  return newArray.join(' ');
}

export function WalletFormatConversion(value) {
  const division = value / 1000000;

  if (division % 1 === 0) {
    const newValue = `${division.toLocaleString('pt-br')},00`;
    return newValue;
  } else {
    const newValue = division.toFixed(2);
    return newValue.replace('.', ',');
  }
}

export const RemoveWhatIsNotNumber = (value) => {
  return value.replace(/[^\d]+/g, '');
};

export const DecodeURI = (value) => {
  const newURI = value.replace('+', '%2B');
  return newURI;
};

export const applyTaxToAmount = (amount, taxValue = 0, taxPercentage = 0) => {
  let taxedAmount = ceilAmount(amount);
  let PERCENTAGE_100 = 100000000;
  //console.log('taxedAmount -> ', taxedAmount);
  if (taxValue > 0) taxedAmount += ceilAmount(taxValue);
  //console.log('taxedAmount -> ', taxedAmount);
  if (taxPercentage > 0) {
    taxedAmount = ceilAmount(
      (amount * PERCENTAGE_100) / (PERCENTAGE_100 - taxPercentage)
    );
  }
  //console.log('taxedAmount -> ', taxedAmount);
  return taxedAmount;
};

export const applyTaxToAmountOld = (
  amount,
  taxValue = 0,
  taxPercentage = 0
) => {
  let PERCENTAGE_100 = 100000000;
  if (
    taxPercentage === 0 ||
    taxPercentage < 0 ||
    taxPercentage > PERCENTAGE_100
  )
    return amount + (taxValue >= 0 ? taxValue : 0);
  return ceilAmount(
    (amount * PERCENTAGE_100) / (PERCENTAGE_100 - taxPercentage)
  );
};

const ceilAmount = (amount) => {
  let value = Math.ceil(parseInt(amount));
  let remainder = value % 10000;
  return value < 0
    ? 10000
    : remainder === 0
    ? value
    : value - remainder + 10000;
};
