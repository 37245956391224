import React from 'react';
// Components
import Tabs from '~/components/tabs';
// Tabs
import Balance from './tabs/Balance';
import Extract from './tabs/Extract';
//import Transfer from './tabs/Transfer';
//import PayWithQRCode from './tabs/PayWithQRCode';

export default function Accounts() {
  const tabsItems = [
    {
      tabPath: '/saldo',
      title: 'Saldo',
      badgeCount: 0,
      component: Balance,
    },
    {
      tabPath: '/extrato',
      title: 'Extrato',
      badgeCount: 0,
      component: Extract,
    },
    /*
    {
      tabPath: '/pagar-com-qr-code',
      title: 'Pagar minhas compras com QR Code',
      badgeCount: 0,
      component: PayWithQRCode,
    },
    {
      tabPath: '/pagar-beneficios-e-doacoes',
      title: 'Pagar benefícios e doações',
      badgeCount: 0,
      component: PayWithQRCode,
    },
    */
  ];
  const tabRedirect = {
    to: '/saldo',
  };

  return (
    <div>
      <Tabs tabsItems={tabsItems} tabRedirect={tabRedirect} />
    </div>
  );
}
