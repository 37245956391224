import React, { useEffect, useContext, useState } from 'react';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { AxiosContext } from '~/contexts/AxiosContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
import { DebugContext } from '~/contexts/DebugContext';
// Components
import Table from '~/components/table';
import Loading from '~/components/loading';
import {
  BadgeCell,
  DateAndTimeCell,
  PrimaryAndSecondaryTextCell,
} from '~/components/table/cells';
import Text from '~/components/typography/text';
// Utils
import {
  WalletFormatConversion,
  CapitalizeString,
} from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function Extract() {
  const { user } = useContext(AuthContext);
  const { CallToTheAPI } = useContext(AxiosContext);
  const { walletAccountNumber } = useContext(WalletAccountContext);
  const { debug } = useContext(DebugContext);
  const isDebug = debug.Wallet.CheckingAccount;
  const [loading, setLoading] = useState(true);
  const [balanceDetailed, setBalanceDetailed] = useState(0);

  const RenderKindCell = (kind, record) => {
    switch (kind) {
      case 'SME-INTERNAL-OFFERING-SINGLE-CS-CC':
        if (user.id === record.giver_person_id) {
          return (
            <PrimaryAndSecondaryTextCell
              primaryText={'Compra de Produto/Serviço'}
              secondaryText={`${CapitalizeString(
                record.product_service_name
              )} - ${CapitalizeString(
                record.receiver_person_social_name ||
                record.receiver_person_name
              )}`}
            />
          );
        }
        return (
          <PrimaryAndSecondaryTextCell
            primaryText={'Venda de Produto/Serviço'}
            secondaryText={`${CapitalizeString(
              record.product_service_name
            )} - ${CapitalizeString(
              record.giver_person_social_name || record.giver_person_name
            )}`}
          />
        );
      case 'SME-INTERNAL-OFFERING-SINGLE-CS-QRCODE-CC':
        if (user.id === record.giver_person_id) {
          return (
            <PrimaryAndSecondaryTextCell
              primaryText={'Resgate de Produto/Serviço'}
              secondaryText={`${CapitalizeString(
                record.product_service_name || 'Produto/Serviço'
              )} - ${CapitalizeString(
                record.receiver_person_social_name ||
                record.receiver_person_name
              )}`}
            />
          );
        }
        return (
          <PrimaryAndSecondaryTextCell
            primaryText={'Venda por QR Code de Produto/Serviço'}
            secondaryText={`${CapitalizeString(
              record.product_service_name || 'Produto/Serviço'
            )} - ${CapitalizeString(
              record.giver_person_social_name || record.giver_person_name
            )}`}
          />
        );
      case 'SME-INTERNAL-DONATION-CC-CS':
      case 'SME-CASH-IN-DONATION-CREDIT':
      case 'SME-CASH-IN-DONATION-DEBIT':
        if (user.id === record.giver_person_id) {
          return (
            <PrimaryAndSecondaryTextCell
              primaryText={'Doação de Produto/Serviço'}
              secondaryText={`${CapitalizeString(
                record.product_service_name || record.donation_product_service_name || 'Desconhecido'
              )} - ${CapitalizeString(
                record.receiver_person_social_name ||
                record.receiver_person_name ||
                'Anônimo'
              )}`}
            />
          );
        }
        return (
          <PrimaryAndSecondaryTextCell
            primaryText={'Recebimento de Doação de Produto/Serviço'}
            secondaryText={`${CapitalizeString(
              record.product_service_name || record.donation_product_service_name || 'Desconhecido'
            )} - ${CapitalizeString(
              record.giver_person_social_name ||
              record.giver_person_name ||
              'Anônimo'
            )}`}
          />
        );
      default:
        return (
          <div>
            <p>{kind}</p>
          </div>
        );
    }
  };

  const RenderValueCell = (amount, record) => {
    if (user.id === record.giver_person_id) {
      return <Text style={{ color: 'red' }} strong>{`${WalletFormatConversion(amount)}`}</Text>
    }
    return <Text style={{ color: 'green' }} strong>{`${WalletFormatConversion(amount)}`}</Text>
  }

  const RenderStatusCell = (status) => {
    switch (status) {
      case 'ACTIVE':
        return <BadgeCell text="Completa" status="success" />;
      case 'PENDING':
        return <BadgeCell text="Pendente" status="warning" />;
      case 'INACTIVE':
      default:
        return <BadgeCell text="Incompleta" status="danger" />;
    }
  };

  const tableColumns = [
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: 'Operação',
      dataIndex: 'kind',
      key: 'kind',
      render: (kind, record) => RenderKindCell(kind, record),
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, record) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 18, marginRight: 10 }}
            src={PickMeCoinIcon()}
            alt="..."
          />
          {RenderValueCell(amount, record)}
        </div>
      )
    },
    {
      title: 'Situação',
      dataIndex: 'status',
      key: 'status',
      render: (status) => RenderStatusCell(status),
    },
  ];

  useEffect(() => {
    async function GetData() {
      if (isDebug) console.log('🚀 API: Get Extract');
      const params = {
        operation: ['Wallet', 'Accounts', 'Extract'],
        urlParams: {
          accountNumber: walletAccountNumber,
          accountType: 'CS',
        },
        data: {
          pagination: {
            sort: {
              by: 'created_at',
              order: 'DESC',
            },
            limit: 100,
          },
          filter: {
            /* 
            nature
            kind
            status
            from: data
            to: data
            income: boolean
            outcome: boolean
            nature, kind e status podem ser string ou array de string
            */
          },
        },
      };
      const response = await CallToTheAPI(params);
      console.log('  ⚡ API Response (Get Extract): ', response);
      if (isDebug) console.log('  ⚡ API Response (Get Extract): ', response);
      if (response.success) {
        setBalanceDetailed(response.body.detailedBalance.elements);
        return setLoading(false);
      }
      return setLoading(false);
    }
    GetData();
  }, [CallToTheAPI, user, walletAccountNumber, isDebug]);

  if (!balanceDetailed) {
    return <Loading />
  }
  return (
    <div>
      <Table
        dataReceived={balanceDetailed}
        tableColumns={tableColumns}
        loading={loading}
      />
    </div>
  );
}
