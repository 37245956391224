import React from 'react';
// Own Components
import OfferingCard from './OfferingCard';
// Components
import Text from '~/components/typography/text';

export default function SelectProductService({
  offerings,
  selectedOffering,
  setSelectedOffering,
}) {
  if (offerings.length === 0) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
        <Text kind="secondary" style={{ textAlign: 'center' }}>
          Você não possui nenhum produto cadastrado que seja equivalente ao
          produto solicitado
        </Text>
      </div>
    );
  }
  return (
    <div>
      {offerings.map((item) => (
        <OfferingCard
          key={item.id}
          item={item}
          selectedOffering={selectedOffering}
          setSelectedOffering={setSelectedOffering}
        />
      ))}
    </div>
  );
}
