import React, { useState, useContext } from 'react';
//Libraries
import { Divider } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
//Contexts
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
//Components
import Title from '~/components/typography/title';
import Text from '~/components/typography/text';
import Card from '~/components/card';
import Button from '~/components/button';
//Utils
import { WalletFormatConversion } from '~/utils/Transformations';
//Assets
import { PickMeCoinIcon, GetIllustration } from '~/utils/SocialMeAssets';

export default function CardBalance({ title, balance, qrCode }) {
  const { isMobile } = useContext(ResponsiveContext);
  const { walletAccount } = useContext(WalletAccountContext);

  const [visible, setVisible] = useState(false);

  function ShowBalance() {
    setVisible(true);
  }
  function HiddenBalance() {
    setVisible(false);
  }

  const QRCodeComponent = () => {
    return (
      <QRCode
        value={qrCode}
        size={isMobile ? 100 : 130}
        bgColor="#ffffff"
        fgColor="#303030"
        level="L"
        includeMargin={false}
        renderAs="svg"
      />
    );
  };

  return (
    <Card bodyStyle={{ padding: 10 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Title level={4}>{title}</Title>
          {visible ? (
            <Button
              type="link"
              icon={<EyeInvisibleOutlined />}
              onClick={() => HiddenBalance()}
            >
              Ocultar saldo
            </Button>
          ) : (
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => ShowBalance()}
            >
              Exibir saldo
            </Button>
          )}
        </div>
        {visible ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div>
              <QRCodeComponent />
            </div>
            <div
              style={{
                height: isMobile ? 100 : 130,
                width: 220,
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <div>
                <Text strong textAlign="right">
                  Conta Social Me
                </Text>
                {qrCode ? (
                  <>
                    <Text textAlign="right">
                      Conta: {walletAccount.account_number} - Dígito:{' '}
                      {walletAccount.account_vd}
                    </Text>
                  </>
                ) : (
                  <p>Carregando</p>
                )}
              </div>
              {isMobile ? <></> : <Divider />}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Title level={4}>
                  <img
                    style={{ width: 24, marginRight: 10 }}
                    src={PickMeCoinIcon()}
                    alt="..."
                  />
                  {WalletFormatConversion(balance || 0)}
                </Title>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div>
              <QRCodeComponent />
            </div>
            <div>
              <img
                style={{ height: isMobile ? 100 : 128 }}
                src={GetIllustration('digitalWallet01')}
                alt="..."
              />
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
