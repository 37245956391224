import styled from 'styled-components';
import { QrcodeOutlined } from '@ant-design/icons';

const borderRadiusValue = '8px';
const size = '70px';
const hoverSize = '170px';
const margin = '10px';

export const Container = styled.div`
  position: fixed;
  width: ${size};
  bottom: ${margin};
  right: ${margin};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  transition: all 0.6s;

  &:hover {
    width: ${hoverSize};
  }
`;

export const Icon = styled(QrcodeOutlined)`
  color: white;
  font-size: 20pt;
  opacity: ${(props) => props.opacity};
  transition: all 0.5s;
`;

export const Text = styled.span`
  font-size: 0pt;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  color: #fff;
  opacity: 0;
  transition: all 0.3s;
`;

export const FloatButton = styled.a`
  width: ${size};
  height: ${size};
  bottom: ${margin};
  right: ${margin};
  background-color: ${(props) => props.backgroundcolor};
  color: #fff;
  border-radius: ${borderRadiusValue};
  box-shadow: 2px 2px 3px #999;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s;

  &:hover {
    width: ${hoverSize};
  }
  &:hover ${Icon} {
    opacity: 1;
    transform: rotate(360deg);
  }
  &:hover ${Text} {
    font-size: 10pt;
    margin-left: 5px;
    opacity: 1;
  }
`;
