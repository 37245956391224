import React, { useEffect, useContext, useState } from 'react';
//Libraries
import { Layout, Row, Col } from 'antd';
import { Link, useHistory } from 'react-router-dom';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
import { ResponsiveContext } from '~/contexts/ResponsiveContext';
//Components
import Navbar from '~/components/navbar/Main';
import Card from '~/components/card';
import Loading from '~/components/loading';
import Title from '~/components/typography/title';
import Button from '~/components/button';
// Environments
import Debug from '~/environments/Debug';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
//Destructuring
const { Content } = Layout;

const isDebug = Debug.Modules.DigitalWallet.Authentication;

export default function Accounts() {
  const { theme } = useContext(ThemeContext);
  const { isMobile } = useContext(ResponsiveContext);

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    async function GetData() {
      if (isDebug) console.log('🚀 API: Get user accounts');
      const params = {
        operation: ['Wallet', 'Accounts', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        if (response.body.accounts.elements.length === 1) {
          if (isDebug) console.log('    User with only one account');
          return history.push(
            `/${response.body.accounts.elements[0].account_number}${response.body.accounts.elements[0].account_vd}`
          );
        }
        if (isDebug) console.log('    User with more than one account');
        setAccounts(response.body.accounts.elements);
        return setLoading(false);
      }
    }
    GetData();
  }, [history]);

  return (
    <Layout
      style={{
        backgroundColor: theme.color.backgroundSecondary,
        maxHeight: isMobile ? 'auto' : '100vh',
      }}
    >
      {loading ? (
        <Loading height="100vh" text="Carregando contas" />
      ) : (
        <>
          <Navbar withoutSidebar />
          <Layout style={{ backgroundColor: theme.color.backgroundSecondary }}>
            <Content
              style={{
                padding: 10,
                backgroundColor: theme.color.backgroundSecondary,
                maxHeight: isMobile ? '100vh' : 'auto',
                overflow: 'auto',
              }}
            >
              <Row>
                {accounts.map((item) => (
                  <Col
                    span={6}
                    key={item.account_number}
                    style={{ padding: 5 }}
                  >
                    <Card
                      bodyStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Title level={4} textAlign="center">
                        {item.account_number}
                      </Title>
                      <Link
                        to={`/${item.account_number}${item.account_vd}`}
                        style={{ marginTop: 20, width: '100%' }}
                      >
                        <Button style={{ width: '100%' }}>Acessar</Button>
                      </Link>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Content>
          </Layout>
        </>
      )}
    </Layout>
  );
}
