import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Addresses() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode gerenciar seus benefícios recebidos através da rede Me."
    />
  );
}
