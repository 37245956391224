import React from 'react';
// Pages
import ACCOUNT from '~/pages/DigitalWallet/components/qrCodeSecondSteps/ACCOUNT';
import DONATION_PRODUCT_SERVICE from '~/pages/DigitalWallet/components/qrCodeSecondSteps/DONATION_PRODUCT_SERVICE';
import PERSONAL from '~/pages/DigitalWallet/components/qrCodeSecondSteps/PERSONAL';
import PRODUCT_SERVICE from '~/pages/DigitalWallet/components/qrCodeSecondSteps/PRODUCT_SERVICE';

export default function QRCodeSecondStep({
  QRCodeInformation,
  setQRCodeInformation,
}) {
  console.log('QRCodeInformation -> ', QRCodeInformation);

  let kind = QRCodeInformation.kind;
  switch (kind) {
    case 'ACCOUNT':
      return <ACCOUNT QRCodeInformation={QRCodeInformation} />;
    case 'DONATION_PRODUCT_SERVICE':
      return <DONATION_PRODUCT_SERVICE QRCodeInformation={QRCodeInformation} />;
    case 'PERSONAL':
      return <PERSONAL QRCodeInformation={QRCodeInformation} />;
    case 'PRODUCT_SERVICE':
      return <PRODUCT_SERVICE QRCodeInformation={QRCodeInformation} />;
    default:
      return (
        <div>
          <p>Error</p>
        </div>
      );
  }
}
