import React, { useContext } from 'react';
// Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';
import Space from '~/components/space';
// Environments
import System from '~/environments/System';
// Utils
import { UserId } from '~/utils/UserInformation';

export default function Header() {
  const { CreatesTheIntent } = useContext(GatewayContext);
  const userId = UserId();

  function AddIntent(type) {
    CreatesTheIntent({
      agent:
        type === 'cashIn'
          ? System.gateway.agents.personManualCashIn
          : System.gateway.agents.personManualCashOut,
      agent_id: userId,
      value: 1000000,
    });
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Title level={4}>Solicitações de Entrada e Saída</Title>
      <Space>
        <Button type="primary" onClick={() => AddIntent('cashIn')}>
          Adicionar
        </Button>
        <Button type="primary" onClick={() => AddIntent('cashOut')}>
          Retirar
        </Button>
      </Space>
    </div>
  );
}
