import React from 'react';
// Components
import Title from '~/components/typography/title';
import Button from '~/components/button';

export default function Header() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Title level={4}>Trocados Me</Title>
      <Button type="primary">Resgatar</Button>
    </div>
  );
}
