import React, { useContext } from 'react';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
//Libraries
import {
  WalletOutlined,
  HomeOutlined,
  QrcodeOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { Route, Switch, Redirect} from 'react-router-dom';
import { Layout } from 'antd';
//Components
import Sidebar from '~/components/sidebar';
import Navbar from '~/components/navbar/Main';
// Pages
import Home from './pages/Home';
import ManualRequisition from './pages/ManualRequisition';
import CheckingAccount from './pages/CheckingAccount';
import BenefitsReceived from './pages/BenefitsReceived';
import SocialCreditAccount from './pages/SocialCreditAccount';

const { Content } = Layout;

export default function WalletDescription() {
  const { theme } = useContext(ThemeContext);

  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Início',
      icon: HomeOutlined,
      url: `/inicio`,
    },
    {
      index: 2,
      title: 'Conta corrente',
      icon: WalletOutlined,
      url: `/conta-corrente`,
    },
    {
      index: 3,
      title: 'Conta Crédito Social',
      icon: WalletOutlined,
      url: `/conta-credito-social`,
    },
    {
      index: 4,
      title: 'Requisições Manuais',
      icon: SwapOutlined,
      url: `/requisicoes-manuais`,
    },
    {
      index: 5,
      title: 'Benefícios recebidos',
      icon: QrcodeOutlined,
      url: `/beneficios-recebidos`,
    },
    {
      index: 6,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <Navbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <Switch>
            <Route exact path={`/`}>
                <Redirect to="/inicio" />
              </Route>
              <Route path={`/inicio`} component={Home} />
              <Route
                path={`/conta-corrente`}
                component={CheckingAccount}
              />
              <Route
                path={`/conta-credito-social`}
                component={SocialCreditAccount}
              />
              <Route
                path={`/requisicoes-manuais`}
                component={ManualRequisition}
              />
              <Route
                path={`/beneficios-recebidos`}
                component={BenefitsReceived}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
