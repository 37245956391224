import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  align-items: ${(props) => props.align || "flex-start"};
  justify-content: ${(props) => props.justify || "flex-start"};
`

export const Div = styled.div``;

export const Tag = styled.div`
  display: flex;
  width: ${props => `${props.width}px`};
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  margin: ${(props) => props.margin || "0"};
  border-radius: 8px;
  padding: 4px 5px;

  & svg {
    color: ${(props) => props.color};
  }
`;

export const ToRight = styled.div`
  margin-right: 6px;
`;

export const ButtonDivider = styled.div`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
