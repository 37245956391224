import React, { useContext } from 'react';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Components
import Text from '~/components/typography/text';
//import Avatar from '~/components/avatar';

export default function AccountContainer() {
    const { walletAccount } = useContext(WalletAccountContext);
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: 10,
            }}
        >
            <Text strong margin="10px 0px">
                {walletAccount.account_number} • {walletAccount.account_vd}
            </Text>
        </div>
    );
}
