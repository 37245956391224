import React, { useContext, memo } from "react";
// Contexts
import { NavLink } from "react-router-dom";
import { Badge, Avatar } from "antd";

import { ThemeContext } from "~/contexts/ThemeContext";
import { ResponsiveContext } from "~/contexts/ResponsiveContext";

import {
  DateInBrazilianFormat,
  HourInBrazilianFormat,
} from "~/utils/DateAndTime";

// Libraries

// Components
import Text from "~/components/typography/text";

import { Flex, Div, Tag, ToRight, ButtonDivider } from "./style";

function ImageWithPrimaryAndSecondaryText({
  primaryText,
  secondaryText,
  image,
  direction,
}) {
  const { isMobile } = useContext(ResponsiveContext);
  const { theme } = useContext(ThemeContext);

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      align="center"
      justify={isMobile ? "center" : "flex-start"}
    >
      {image ?
      <Avatar
      src={image}
      style={{ margin: isMobile ? "0 0 10px 0" : "0 10px 0 0" }}
    />
    :
    <Avatar
        style={{
          color: theme.color.textOnPrimaryBackground,
          backgroundColor: theme.color.primary,
          margin: isMobile ? "0 0 10px 0" : "0 10px 0 0",
        }}
      >
        {primaryText ? primaryText.charAt(0) : ""}
      </Avatar>
      }

      <Flex
        direction="column"
        align={isMobile && "center"}
        justify={isMobile && "center"}
      >
        <Text strong>{primaryText}</Text>
        <Text type="secondary" style={isMobile && { textAlign: "center" }}>
          {secondaryText}
        </Text>
      </Flex>
    </Flex>
  );
}
export const ImageWithPrimaryAndSecondaryTextCell = memo(
  ImageWithPrimaryAndSecondaryText
);

function PrimaryAndSecondaryText({
  primaryText,
  secondaryText,
  align,
  textAlign,
  direction
}) {
  return (
    <Flex align={align} direction={direction || 'column'}>
      <Text style={{ textAlign }} strong>
        {primaryText}
      </Text>
      <Text style={{ textAlign }} type="secondary">
        {secondaryText}
      </Text>
    </Flex>
  );
}

export const PrimaryAndSecondaryTextCell = memo(PrimaryAndSecondaryText);

function DateAndTime({ timestamp }) {
  const { isMobile } = useContext(ResponsiveContext);

  const date = DateInBrazilianFormat(timestamp);
  const time = HourInBrazilianFormat(timestamp);

  return (
    <Flex direction="column">
      <Text
        strong
        style={isMobile && { textAlign: "center" }}
      >{`Dia: ${date}`}</Text>
      <Text
        style={isMobile && { textAlign: "center" }}
      >{`Horário: ${time}`}</Text>
    </Flex>
  );
}
export const DateAndTimeCell = memo(DateAndTime);

function PrimaryText({ text, textAlign, strong }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Div>
      <Text
        style={{ textAlign }}
        strong={strong || false}
        color={theme.color.textOnSecondaryBackground}
      >
        {text}
      </Text>
    </Div>
  );
}
export const PrimaryTextCell = memo(PrimaryText);

function TextLink({ text, url }) {
  const { theme } = useContext(ThemeContext);
  return (
    <Flex>
      <NavLink to={url || "#"}>
        <Text strong color={theme.color.primary}>
          {text}
        </Text>
      </NavLink>
    </Flex>
  );
}
export const TextLinkCell = memo(TextLink);

function Tags({ tags, tag, color, fieldName, random }) {
  const randomTags = ["tag", "tag"];
  const colors = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
  ];
  if (random) {
    return (
      <Div>
        {randomTags.map((item, index) => {
          const tagColor =
            color || colors[Math.floor(Math.random() * colors.length)];
          return (
            <Tag color={tagColor} key={index} style={{ marginBottom: 5 }}>
              {item.toUpperCase()}-{tagColor.toUpperCase()}
            </Tag>
          );
        })}
      </Div>
    );
  }
  if (tags) {
    return (
      <Div>
        {tags.map((item) => {
          const tagColor =
            color || colors[Math.floor(Math.random() * colors.length)];
          return (
            <Tag color={tagColor} key={item._id}>
              {item[fieldName].toUpperCase()}
            </Tag>
          );
        })}
      </Div>
    );
  }
  if (tag) {
    return (
      <Div>
        <Tag color={color || colors[Math.floor(Math.random() * colors.length)]}>
          {tag}
        </Tag>
      </Div>
    );
  }
}
export const TagsCell = memo(Tags);

function BadgeContainer({ text, status }) {
  return (
    <Flex>
      <Badge status={status} text={text} />
    </Flex>
  );
}
export const BadgeCell = memo(BadgeContainer);

function TwoActionButton({ PrimaryItem, SecondaryItem, align }) {
  return (
    <Flex align={align}>
      {PrimaryItem}
      <ButtonDivider />
      {SecondaryItem}
    </Flex>
  );
}
export const TwoActionButtonCell = memo(TwoActionButton);

function ImageWithText({ text, size, src, color, Icon }) {
  const { isMobile } = useContext(ResponsiveContext);

  return (
    <Flex align="center" direction={isMobile && "column"}>
      {src ? (
        <Avatar
          style={{
            margin: isMobile ? "10px 0 0 0" : "0 10px 0 0",
          }}
          size={size || 30}
          src={src}
          alt="..."
        />
      ) : (
        <Icon
          style={{
            marginRight: "12px",
            fontSize: size || 25,
            color,
          }}
        />
      )}

      <Text style={{ textAlign: isMobile ? "center" : "left" }}>{text}</Text>
    </Flex>
  );
}
export const ImageWithTextCell = memo(ImageWithText);

function IconWithText({ text, color, icon, margin, width }) {
  const { theme } = useContext(ThemeContext);

  function setBackgroundColor() {
    switch (color) {
      case "success":
        return theme.color.success;
      case "processing":
        return theme.color.blue;
      case "error":
        return theme.color.danger;
      default:
        return theme.color.white;
    }
  }

  return (
    <Tag
      color={theme.color.textOnPrimaryBackground}
      backgroundColor={setBackgroundColor()}
      width={width}
      margin={margin}
    >
      <ToRight>{icon}</ToRight>

      <Text strong color={theme.color.textOnPrimaryBackground}>
        {text}
      </Text>
    </Tag>
  );
}
export const IconWithTextCell = memo(IconWithText);
