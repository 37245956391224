import React from 'react';
// Components
import Button from '~/components/button';
// Environments
import System from '~/environments/System';

export default function AddProductService() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0px',
      }}
    >
      <a
        href={`https://${System.hostname.partner}/meu-negocio`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button type="dashed">Cadastrar novo produto</Button>
      </a>
    </div>
  );
}
