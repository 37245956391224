import React, { useContext } from 'react';
// Libraries
import { GiTwoCoins } from 'react-icons/gi';
//Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
// Environments
import System from '~/environments/System';
// Standalone Components
import { Container } from './style';
// Utils
import {
  CapitalizeString,
  WalletFormatConversion,
} from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function PRODUCT_SERVICE({ QRCodeInformation }) {
  const { CreatesTheIntent } = useContext(GatewayContext);
  const { theme } = useContext(ThemeContext);

  const Header = () => {
    return (
      <div>
        <Title level={4} style={{ textAlign: 'center', margin: 0 }}>
          {`QR Code de ${
            QRCodeInformation.ps_offering_kind === 'PRODUCT'
              ? 'Produto'
              : 'Serviço'
          }`}
        </Title>
      </div>
    );
  };

  const ProductContainer = () => {
    return (
      <div>
        <Title level={3} style={{ textAlign: 'center', margin: 0 }}>
          {CapitalizeString(QRCodeInformation.ps_offering_name)}
        </Title>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
          }}
        >
          <img
            style={{ width: 36, marginRight: 10 }}
            src={PickMeCoinIcon()}
            alt="Social Me Coin"
          />
          <Title level={2} style={{ textAlign: 'center', margin: 0 }}>
            {WalletFormatConversion(QRCodeInformation.ps_offering_value)}
          </Title>
        </div>
      </div>
    );
  };

  const Footer = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <Avatar
            src={QRCodeInformation.person_avatar}
            style={{ marginRight: 10 }}
            size={48}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Text strong>
              {QRCodeInformation.person_social_name ||
                QRCodeInformation.person_name}
            </Text>
            <Text kind="secondary">Loja parceira</Text>
          </div>
        </div>
        <Button
          type="primary"
          icon={<GiTwoCoins />}
          size="large"
          anotherLibraryIcon
          style={{ width: '100%' }}
          onClick={() =>
            CreatesTheIntent({
              agent: System.gateway.agents.partnerProductService,
              agent_id: QRCodeInformation.ps_offering_id,
              value: QRCodeInformation.ps_offering_value,
            })
          }
        >
          {`Comprar ${
            QRCodeInformation.ps_offering_kind === 'PRODUCT'
              ? 'produto'
              : 'serviço'
          }`}
        </Button>
      </div>
    );
  };

  return (
    <Container backgroundcolor={theme.color.backgroundSecondaryLight}>
      <Header />
      <ProductContainer />
      <Footer />
    </Container>
  );
}
