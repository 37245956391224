import React, { useEffect, useContext, useState } from 'react';
// Contexts
import { AuthContext } from '~/contexts/AuthContext';
import { AxiosContext } from '~/contexts/AxiosContext';
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
import { DebugContext } from '~/contexts/DebugContext';
// Libraries
import { Row, Col } from 'antd';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Own components
import CardBalance from './components/CardBalance';

export default function BalanceContent() {
  const { user } = useContext(AuthContext);
  const { CallToTheAPI } = useContext(AxiosContext);
  const { walletAccountNumber } = useContext(WalletAccountContext);
  const { debug } = useContext(DebugContext);
  const isDebug = debug.Wallet.CheckingAccount;

  const [loadingBalance, setLoadingBalance] = useState(true);
  const [loadingQRCode, setLoadingQRCode] = useState(true);
  const [balance, setBalance] = useState(0);
  const [qrCodeCS, setQRCodeCS] = useState('undefined');

  useEffect(() => {
    async function GetBalance() {
      if (isDebug) console.log('🚀 API: GetBalance');
      const params = {
        operation: ['Wallet', 'Accounts', 'Balance'],
        urlParams: {
          personId: user._id,
          accountNumber: walletAccountNumber,
        },
      };
      const response = await CallToTheAPI(params);
      if (isDebug) console.log('  ⚡ API Response (GetBalance): ', response);
      if (response.success) {
        setBalance(response.body.balance);
        return setLoadingBalance(false);
      }
      return setLoadingBalance(false);
    }
    if (user) {
      GetBalance();
    }
  }, [CallToTheAPI, user, walletAccountNumber, isDebug]);

  useEffect(() => {
    async function GetQRCodeCS() {
      if (isDebug) console.log('🚀 API: Get QR Code CS');
      const params = {
        operation: ['Wallet', 'QRCodes', 'Get'],
        urlParams: {
          accountType: 'CS',
        },
      };
      const response = await CallToTheAPI(params);
      console.log(response);
      if (isDebug)
        console.log('  ⚡ API Response (Get QR Code CS): ', response);
      if (response.success) {
        setQRCodeCS(response.body.QRCode._id);
        return setLoadingQRCode(false);
      }
    }

    GetQRCodeCS();
  }, [CallToTheAPI, walletAccountNumber, isDebug]);

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div style={{ padding: 5 }}>
            {loadingBalance || loadingQRCode ? (
              <Card bodyStyle={{ padding: 10 }}>
                <div
                  style={{
                    height: 150,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Loading height="50px" text="Buscando informações" />
                </div>
              </Card>
            ) : (
              <CardBalance
                title="Conta Crédito Social"
                balance={0 || balance.SME.CS}
                qrCode={qrCodeCS}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
