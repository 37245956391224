import React, { useContext } from 'react';
// Libraries
import {
  DateAndTimeCell,
  PrimaryTextCell,
  BadgeCell,
} from '~/components/table/cells';
import {
  RiseOutlined,
  FallOutlined
} from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Table from '~/components/table';
import Text from '~/components/typography/text';
// Utils
import { WalletFormatConversion } from '~/utils/Transformations';

export default function Content({ data }) {
  const { theme } = useContext(ThemeContext);

  function GetNature(nature) {
    if (nature === 'CASH-IN') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <RiseOutlined style={{ marginRight: 5, color: theme.color.success }} />
          <Text style={{color: theme.color.success }}>Entrada de Me's</Text>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FallOutlined style={{ marginRight: 5, color: theme.color.danger }} />
        <Text style={{color: theme.color.danger }}>Saída de Me's</Text>
      </div>
    );;
  }
  function GetStatus(status) {
    if (status === 'ACTIVE') {
      return 'success';
    }
    if (status === 'PENDING') {
      return 'warning';
    }
    return 'error';
  }
  function GetStatusText(status) {
    if (status === 'ACTIVE') {
      return 'Completa';
    }
    if (status === 'PENDING') {
      return 'Pendente';
    }
    return 'Cancelada';
  }
  const tableColumns = [
    {
      title: 'Data',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => <DateAndTimeCell timestamp={created_at} />,
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => (
        <PrimaryTextCell text={WalletFormatConversion(amount)} />
      ),
    },
    {
      title: 'Operação',
      dataIndex: 'nature',
      key: 'nature',
      render: (nature) => <PrimaryTextCell text={GetNature(nature)} />,
    },
    {
      title: 'Situação',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <BadgeCell text={GetStatusText(status)} status={GetStatus(status)} />
      ),
    },
    /*
    {
      title: 'Description',
      dataIndex: 'kind',
      key: 'kind',
      render: (kind, record) => PrepareDescriptionField(kind, record),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      render: (value) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 24, marginRight: 10 }}
            src={PickMeCoinIcon()}
            alt="..."
          />
          {WalletFormatConversion(value)}
        </div>
      ),
    },
    */
  ];
  return (
    <div>
      <Table dataReceived={data} tableColumns={tableColumns} />
    </div>
  );
}
