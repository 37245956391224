import React, { useContext } from 'react';
// Libraries
import { Form } from 'antd';
import { GiTwoCoins } from 'react-icons/gi';
//Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Avatar from '~/components/avatar';
import Button from '~/components/button';
import Text from '~/components/typography/text';
import Title from '~/components/typography/title';
import FormItem from '~/components/form/FormItem';
import InputNumber from '~/components/inputNumber';
// Environments
import System from '~/environments/System';
// Standalone Components
import { Container } from './style';
// Utils
import { CapitalizeString } from '~/utils/Transformations';
import { WalletFormatParser } from '~/utils/Parsers';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function ACCOUNT({ QRCodeInformation }) {
  const { CreatesTheIntent } = useContext(GatewayContext);
  const { theme } = useContext(ThemeContext);
  const [form] = Form.useForm();

  function onFinish(data) {
    const intention = {
      agent: System.gateway.agents.person,
      agent_id: QRCodeInformation.person_id,
      value: WalletFormatParser(data.value),
    };
    CreatesTheIntent(intention);
  }

  const Header = () => {
    return (
      <div>
        <Title level={4} style={{ textAlign: 'center', margin: 0 }}>
          {`QR Code de conta`}
        </Title>
      </div>
    );
  };

  const PersonContainer = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Avatar src={QRCodeInformation.person_avatar} size={128} />
        <Title level={4} style={{ textAlign: 'center', margin: 0 }}>
          {CapitalizeString(
            QRCodeInformation.person_social_name ||
              QRCodeInformation.person_name
          )}
        </Title>
        <Text>Digite abaixo quantos Me's você deseja transferir</Text>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FormItem
            name={['value']}
            style={{ margin: 0 }}
            item={
              <InputNumber
                style={{ width: 110 }}
                size="large"
                step={0.1}
                min={1}
                decimalSeparator={','}
              />
            }
            rules={[
              {
                required: true,
                message: 'Insira um valor',
              },
            ]}
          />
          <img
            style={{ width: 30, marginLeft: 10 }}
            src={PickMeCoinIcon()}
            alt="Social Me Coin"
          />
        </div>
      </div>
    );
  };

  const Footer = () => {
    return (
      <div>
        <Button
          type="primary"
          icon={<GiTwoCoins />}
          size="large"
          anotherLibraryIcon
          style={{ width: '100%' }}
          htmlType="submit"
        >
          {`Realizar transferência`}
        </Button>
      </div>
    );
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      size="small"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
    >
      <Container backgroundcolor={theme.color.backgroundSecondaryLight}>
        <Header />
        <PersonContainer />
        <Footer />
      </Container>
    </Form>
  );
}
