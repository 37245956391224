import React, { useEffect, useState } from 'react';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function ManualRequests() {
  const [manualRequests, setManualRequests] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetManualRequests() {
      const params = {
        operation: ['Wallet', 'ManualRequests', 'Get'],
        data: {
          pagination: {
            sort: {
              by: 'created_at',
              order: 'desc',
            },
          },
          filter: {
            cashin: true,
            cashout: true,
            status: ['ACTIVE', 'INACTIVE', 'PENDING'],
          },
        },
      };
      const response = await SocialMeAPI(params);
      console.log('  ⚡ API Response (Get ManualRequests): ', response);
      if (response.success) {
        setManualRequests(response.body.manualOperations.elements);
        setLoadingContent(false);
      }
    }
    GetManualRequests();
  }, []);

  if (loadingContent) {
    return <Loading text="Carregando suas requisições" />;
  }

  return (
    <div>
      <Card>
        <Header />
        <Content data={manualRequests} />
      </Card>
    </div>
  );
}
