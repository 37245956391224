import React, { useContext } from 'react';
// Libraries
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { Layout } from 'antd';
import {
  WalletOutlined,
  HomeOutlined,
  QrcodeOutlined,
  SwapOutlined,
  MoneyCollectOutlined
} from '@ant-design/icons';
// Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Sidebar from '~/components/sidebar';
import Navbar from '~/components/navbar/Main';
import FloatingWalletButton from '~/pages/DigitalWallet/components/floatingWalletButton';
// Pages
import Home from './pages/Home';
import ManualRequisition from './pages/ManualRequisition';
import CheckingAccount from './pages/CheckingAccount';
import BenefitsReceived from './pages/BenefitsReceived';
// import QRCodes from './pages/QRCodes';
// import Card from './pages/Card';
import SocialCreditAccount from './pages/SocialCreditAccount';
// import Payments from './pages/Payments';
// import Refills from './pages/Refills';
// import Routes from './pages/Routes';
// import TestQRCode from './pages/TestQRCode';
import ChangeMe from './pages/ChangeMe';
// Destructuring
const { Content } = Layout;

export default function GiverDashboard() {
  const { theme } = useContext(ThemeContext);

  const { accountNumber } = useParams();
  const sidebarItems = [
    {
      index: 0,
      title: 'First',
    },
    {
      index: 1,
      title: 'Início',
      icon: HomeOutlined,
      url: `/${accountNumber}/inicio`,
    },
    {
      index: 2,
      title: 'Conta corrente',
      icon: WalletOutlined,
      url: `/${accountNumber}/conta-corrente`,
    },
    {
      index: 3,
      title: 'Conta Crédito Social',
      icon: WalletOutlined,
      url: `/${accountNumber}/conta-credito-social`,
    },
    {
      index: 4,
      title: 'Entrada e Saída',
      icon: SwapOutlined,
      url: `/${accountNumber}/solicitacoes-entrada-saida`,
    },
    /*
    {
      index: 2,
      title: 'Depósito Conta Me',
      icon: <HomeOutlined />,
      url: `/${accountNumber}/solicitacoes-entrada-saida`,
    },
    {
      index: 3,
      title: 'Conta corrente',
      icon: <WalletOutlined />,
      url: `/${accountNumber}/conta-corrente`,
    },
 
    {
      index: 5,
      title: 'Cartão',
      icon: <WalletOutlined />,
      url: `/${accountNumber}/cartao`,
    },
    {
      index: 6,
      title: 'Pagar contas',
      icon: <WalletOutlined />,
      url: `/${accountNumber}/pagamentos`,
    },
    {
      index: 7,
      title: 'Recargas',
      icon: <WalletOutlined />,
      url: `/${accountNumber}/recargas`,
    },
    
    
    {
      index: 4,
      title: 'QR Codes',
      icon: QrcodeOutlined,
      url: `/${accountNumber}/qr-codes`,
    },
    */
    {
      index: 5,
      title: 'Benefícios recebidos',
      icon: QrcodeOutlined,
      url: `/${accountNumber}/beneficios-recebidos`,
    },
    {
      index: 6,
      title: 'Trocados Me',
      icon:  MoneyCollectOutlined,
      url: `/${accountNumber}/trocados-me`,
    },
    {
      index: 7,
      title: 'Last',
    },
  ];

  return (
    <div>
      <Layout
        style={{
          backgroundColor: theme.color.backgroundPrimary,
          height: '100vh',
        }}
      >
        <Sidebar items={sidebarItems} />
        <Layout
          style={{
            padding: 10,
            backgroundColor: theme.color.backgroundSecondary,
          }}
        >
          <Navbar />
          <Content
            style={{
              overflow: 'auto',
            }}
          >
            <FloatingWalletButton />
            <Switch>
              <Route exact path={`/${accountNumber}`}>
                <Redirect to={`${accountNumber}/inicio`} />
              </Route>
              <Route path={`/${accountNumber}/inicio`} component={Home} />
              <Route
                path={`/${accountNumber}/conta-corrente`}
                component={CheckingAccount}
              />
              <Route
                path={`/${accountNumber}/conta-credito-social`}
                component={SocialCreditAccount}
              />
              <Route
                path={`/${accountNumber}/solicitacoes-entrada-saida`}
                component={ManualRequisition}
              />
              <Route
                path={`/${accountNumber}/beneficios-recebidos`}
                component={BenefitsReceived}
              />
              <Route
                path={`/${accountNumber}/trocados-me`}
                component={ChangeMe}
              />
              {/* 
                          <Route path={`/${accountNumber}/qr-codes`} component={QRCodes} />
              <Route
                path={`/${accountNumber}/conta-corrente`}
                component={CheckingAccount}
              />
              
              <Route path={`/${accountNumber}/cartao`} component={Card} />
              
              <Route
                path={`/${accountNumber}/pagamentos`}
                component={Payments}
              />
              <Route
                path={`/${accountNumber}/conta-corrente`}
                component={CheckingAccount}
              />
              
              
              <Route path={`/${accountNumber}/recargas`} component={Refills} />
              <Route path={`/${accountNumber}/rotas`} component={Routes} />
              
              */}
              {/* 
            <Route exact path={`*`}>
              <Redirect to="/inicio" />
            </Route>
            */}
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}
