import React, { useContext, useState, useEffect } from 'react';
// Libraries
import { GiTwoCoins } from 'react-icons/gi';
//Contexts
import { GatewayContext } from '~/contexts/GatewayContext';
import { ThemeContext } from '~/contexts/ThemeContext';
// Own Components
import ChosenOfferingCard from './ChosenOfferingCard';
// Components
import Button from '~/components/button';
import Divider from '~/components/divider';
import InputNumber from '~/components/inputNumber';
import Text from '~/components/typography/text';
// Environments
import System from '~/environments/System';
// Utils
import { WalletFormatParser } from '~/utils/Parsers';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

function FieldInput(value) {
  let valueString = value.toString();
  let splits = valueString.split('');
  let decimals = valueString.substring(splits.length - 6, splits.length);
  let whole = valueString.substring(0, splits.length - 6);
  let newValueString = `${whole}.${decimals}`;
  let newValue = Number(newValueString);
  return newValue;
}

export default function ChosenOfferingContainer({
  chosenOffering,
  setChosenOffering,
  setSelectedOffering,
  QRCodeInformation,
}) {
  const { CreatesTheIntent } = useContext(GatewayContext);
  const { theme } = useContext(ThemeContext);
  const [amount, setAmount] = useState(0);
  const [unitaryValue, setUnitaryValue] = useState(
    FieldInput(chosenOffering.value)
  );
  const [discont, setDiscont] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [disabledButton, setDisabledButton] = useState(true);
  useEffect(() => {
    console.log(1);
    let discontValue = discont / 100;
    let discontAmount = unitaryValue * discontValue;
    let amountWithDiscont = unitaryValue - discontAmount;
    let totalAmount = amountWithDiscont * quantity;
    setAmount(totalAmount);
    if (QRCodeInformation.amount.SME > WalletFormatParser(totalAmount)) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [quantity, unitaryValue, discont, QRCodeInformation]);
  return (
    <div style={{ marginTop: 10 }}>
      <Divider>Produto selecionado</Divider>
      <ChosenOfferingCard
        item={chosenOffering}
        setChosenOffering={setChosenOffering}
        setSelectedOffering={setSelectedOffering}
      />
      <Divider>Informações adicionais</Divider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <Text>Valor unitário: </Text>
        <InputNumber
          size="small"
          style={{ width: 100 }}
          defaultValue={unitaryValue}
          step={1}
          min={1}
          onChange={(value) => setUnitaryValue(value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <Text>Quantidade: </Text>
        <InputNumber
          size="small"
          style={{ width: 100 }}
          defaultValue={1}
          precision={1}
          step={1}
          min={1}
          onChange={(value) => setQuantity(value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <Text>Desconto: </Text>
        <InputNumber
          size="small"
          defaultValue={0}
          precision={1}
          min={0}
          max={100}
          style={{ width: 100 }}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
          onChange={(value) => setDiscont(value)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <Text>Valor total: </Text>
        <Text strong style={{ fontSize: '14pt' }}>
          {amount.toFixed(2)}
          <img
            style={{ width: 24, marginLeft: 10 }}
            src={PickMeCoinIcon()}
            alt="Social Me Coin"
          />
        </Text>
      </div>
      <div style={{ marginTop: 10 }}>
        <Button
          disabled={disabledButton}
          type="primary"
          icon={<GiTwoCoins />}
          size="large"
          anotherLibraryIcon
          style={{ width: '100%' }}
          onClick={() =>
            CreatesTheIntent({
              agent: System.gateway.agents.sellSingleDonation,
              agent_id: chosenOffering.id,
              value: WalletFormatParser(amount),
              parameters: {
                QRCodeInformation: QRCodeInformation,
                quantity: quantity,
              },
            })
          }
        >
          {`Realizar venda`}
        </Button>
        {disabledButton && (
          <Text
            style={{
              textAlign: 'center',
              color: theme.color.danger,
              marginTop: 5,
            }}
          >
            Valor da compra maior que o valor disponível
          </Text>
        )}
      </div>
    </div>
  );
}
