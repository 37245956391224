import React, { useEffect, useState, useContext } from 'react';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
// Owm Components
import WalletAccountAuthentication from './components/WalletAccountAuthentication';
// Components
import Loading from '~/components/loading';

import PrintContent from './views/InvoicePrinter/PrintContent';

export default function QRCodeDrawerContent({ propertys }) {
  const { walletTokenValid, walletAccount, walletPassword } = useContext(
    WalletAccountContext
  );
  const [QRCode, setQRCode] = useState();
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetQRCode() {
      console.log('propertys -> ', propertys);
      const params = {
        operation: ['QRCode', 'GetDonationProductService'],
        urlParams: {
          donationRequestProductServiceId:
            propertys.donation_product_service_id,
          operationId: propertys.regarding_to_operation || propertys._id,
        },
        data: {
          account_number: walletAccount.account_number,
          account_vd: walletAccount.account_vd,
          agency_number: walletAccount.agency_number,
          password: walletPassword,
          forceNew: true,
        },
      };
      console.log(params);
      const response = await SocialMeAPI(params);
      console.log('  ⚡ API Response GetQRCode: ', response);
      if (response.success) {
        setQRCode(response.body.QRCode);
        setLoadingContent(false);
      }
    }
    if (walletTokenValid) {
      GetQRCode();
    }
  }, [walletTokenValid, propertys, walletAccount, walletPassword]);
  /*
  const RenderContent = () => {
    switch (view) {
      case 'TraditionalPrinter':
        return <TraditionalPrinter QRCode={QRCode} />;
      case 'InvoicePrinter':
        return <InvoicePrinter QRCode={QRCode} />;
      default:
        return <></>;
    }
  };
*/
  if (!walletTokenValid) {
    return <WalletAccountAuthentication />;
  }

  if (loadingContent) {
    return (
      <Loading />
    )
  }

  return (
    <div>
      <PrintContent QRCode={QRCode} />
    </div>
  );
}
