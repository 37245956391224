import React from 'react';
// Components
import Title from '~/components/typography/title';

export default function Header() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Title level={4}>Doações recebidas</Title>
    </div>
  );
}
