import React, { useEffect, useContext, useState } from 'react';
//Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
//Libraries
import { useParams, useHistory } from 'react-router-dom';
//Components
import Loading from '~/components/loading';
//Own Components
import WalletDashboard from './pages/WalletDashboard';
// Environments
import Debug from '~/environments/Debug';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';
import Auth from './pages/Auth';

const isDebug = Debug.Modules.DigitalWallet.Authentication;

export default function SecurityLayer() {
  const { FillAccount, walletAccount } = useContext(WalletAccountContext);
  const history = useHistory();
  const { accountNumber } = useParams();
  const [loading, setLoading] = useState(true);
  const [accountForContext, setAccountForContext] = useState();

  useEffect(() => {
    async function GetData() {
      if (isDebug) console.log('🚀 API: Get user accounts');
      const params = {
        operation: ['Wallet', 'Accounts', 'Get'],
      };
      const response = await SocialMeAPI(params);
      if (isDebug) console.log('  ⚡ API Response: ', response);
      if (response.success) {
        const realAccountNumber = `${response.body.accounts.elements[0].account_number}${response.body.accounts.elements[0].account_vd}`;
        if (isDebug)
          console.log('    Real Account Number: ' + realAccountNumber);
        if (isDebug) console.log('    URL account number: ' + accountNumber);
        for (let i = 0; i < response.body.accounts.elements.length; i++) {
          if (accountNumber === realAccountNumber) {
            if (isDebug)
              console.log('    ✔ The account really belongs to the user');
            setAccountForContext(response.body.accounts.elements[i]);
            console.log(response.body.accounts.elements[i]);
            return setLoading(false);
          }
        }
        if (isDebug)
          console.log('    ❌ The account does not belong to the user');
        return history.push(`/`);
      }
    }
    GetData();
  }, [accountNumber, history]);

  useEffect(() => {
    if (accountForContext) {
      FillAccount(accountForContext);
    }
  }, [accountForContext, FillAccount]);

  if (walletAccount) {
    if (walletAccount.status === 'INACTIVE') {
      return <Auth />;
    }
  }

  return (
    <div>
      {loading ? (
        <Loading height="100vh" text="Preparando acesso" />
      ) : walletAccount && walletAccount.status === 'INACTIVE' ? (
        <Auth />
      ) : (
        <WalletDashboard />
      )}
    </div>
  );
}
