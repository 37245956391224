import React, { useContext } from 'react';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Card from '~/components/card';
import Text from '~/components/typography/text';
import Checkbox from '~/components/checkbox';
// Utils
import {
  CapitalizeString,
  WalletFormatConversion,
} from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function OfferingCard({
  item,
  selectedOffering,
  setSelectedOffering,
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <div style={{ padding: 5, margin: '5px 0px' }}>
      <Card bodyStyle={{ padding: 0 }}>
        <div
          onClick={() => setSelectedOffering(item)}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
            border: `1px solid ${
              selectedOffering && selectedOffering.id === item.id
                ? theme.color.primary
                : theme.color.borderColor
            }`,
            borderRadius: 8,
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={
                selectedOffering && selectedOffering.id === item.id
                  ? true
                  : false
              }
            />
            <Text style={{ marginLeft: 10 }}>
              {CapitalizeString(item.name)}
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginRight: 10 }}>
              {WalletFormatConversion(item.value)}
            </Text>
            <img
              style={{ width: 16 }}
              src={PickMeCoinIcon()}
              alt="Social Me Coin"
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
