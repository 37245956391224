import React, { useContext, useState } from 'react';
// Libraries
import { Form } from 'antd';
// Contexts
import { WalletAccountContext } from '~/contexts/WalletAccountContext';
// Components
import Text from '~/components/typography/text';
import Input from '~/components/input';
import Button from '~/components/button';
import FormItem from '~/components/form/FormItem';

export default function AuthAccount() {
  const { WalletLogin } = useContext(WalletAccountContext);
  const [form] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);

  async function Login(password) {
    setLoadingButton(true);
    const response = await WalletLogin(password);
    if (response.success) {
      setLoadingButton(false);
    }
    return setLoadingButton(false);
  }
  const onFinish = (data) => {
    //console.log('Data -> ', data);
    Login(data.password);
  };

  return (
    <Form
      labelCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 } }}
      form={form}
      size="small"
      layout="vertical"
      onFinish={onFinish}
      scrollToFirstError
    >
      <FormItem
        label={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Text strong>Senha</Text>
          </div>
        }
        name={['password']}
        item={<Input type="password" maxLength={4} />}
        rules={[
          {
            required: true,
            message: 'Uma senha é obrigátoria',
          },
        ]}
      />
      <Button
        style={{ width: '100%' }}
        type="primary"
        size="large"
        loading={loadingButton}
        onClick={() => {
          form
            .validateFields()
            .then((values) => {
              onFinish(values);
            })
            .catch((info) => {
              // console.log('Validate Failed:', info);
            });
        }}
      >
        Entrar
      </Button>
    </Form>
  );
}
