import React, { useContext } from 'react';
// Libraries
import { CloseOutlined } from '@ant-design/icons';
//Contexts
import { ThemeContext } from '~/contexts/ThemeContext';
// Components
import Card from '~/components/card';
import Text from '~/components/typography/text';
// Utils
import {
  CapitalizeString,
  WalletFormatConversion,
} from '~/utils/Transformations';
import { PickMeCoinIcon } from '~/utils/SocialMeAssets';

export default function ChosenOfferingCard({
  item,
  setChosenOffering,
  setSelectedOffering,
}) {
  const { theme } = useContext(ThemeContext);
  function Cancel() {
    setSelectedOffering();
    setChosenOffering();
  }
  return (
    <div style={{ padding: 5, margin: '5px 0px' }}>
      <Card bodyStyle={{ padding: 0 }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 10,
            border: `1px solid ${theme.color.primary}`,
            borderRadius: 8,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CloseOutlined
              style={{ color: theme.color.danger }}
              onClick={() => Cancel()}
            />
            <Text style={{ marginLeft: 10 }}>
              {CapitalizeString(item.name)}
            </Text>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Text style={{ marginRight: 10 }}>
              {WalletFormatConversion(item.value)}
            </Text>
            <img
              style={{ width: 16 }}
              src={PickMeCoinIcon()}
              alt="Social Me Coin"
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
