import React from 'react';
// Own components
import BalanceContent from './components/BalanceContent';
//import PayWithQRCode from './components/PayWithQRCode';

export default function Home() {
  return (
    <div>
      <BalanceContent />
      {/* 
      <PayWithQRCode />
      */}
    </div>
  );
}
