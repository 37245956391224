import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Addresses() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode irá receber informações rápidas sobre o seu negócio, como quantas vendas já foram realizadas, quantos clientes atendidos e o seu ganho total."
    />
  );
}
