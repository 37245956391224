import React from 'react';
//Utils
import { ExistingUser } from '~/utils/UserInformation';
// Pages
import WalletDescription from './WalletDescription';
import Start from './Start';

export default function WalletCheck() {
  return ExistingUser() ? <Start /> : <WalletDescription />;
}
