import React from "react";
import Card from "~/components/card";
import Text from "~/components/typography/text";

import { Cell, Space, MainCollapse, MainPanel } from "./style";

export default function ResponsiveTable({ theme, data, columns, expandable }) {
  const cardStyle = {
    marginBottom: 20,
  };

  return (
    <>
      {data.map((item) => (
        <Card style={cardStyle} key={item.id} bodyStyle={{ padding: 5 }}>
          {columns.map((column) => (
            <Cell key={column.key}>
              <Space>
                <Text strong>{column.title}</Text>
              </Space>
              {column.render(item[column.key], item)}
            </Cell>
          ))}

          {expandable ? (
            <MainCollapse>
              <MainPanel
                backgroundColor={theme.color.white}
                color={theme.color.textOnSecondaryBackground}
                header={expandable.title || "Detalhes"}
                key="1"
              >
                {expandable.expandedRowRender(item)}
              </MainPanel>
            </MainCollapse>
          ) : (
            () => {}
          )}
        </Card>
      ))}
    </>
  );
}
