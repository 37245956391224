import React from 'react';
// Components
import Text from '~/components/typography/text';
import Card from '~/components/card';
// Own Components
import AccountContainer from './components/AccountContainer';
import AuthAccount from './components/AuthAccount';

export default function AccountAuthentication() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          maxWidth: 350,
        }}
      >
        <AccountContainer />
        <Text style={{ textAlign: 'center' }} margin="10px 0px">
          Confirme sua senha para continuar
        </Text>
        <Card bodyStyle={{ padding: 10 }}>
          <AuthAccount />
        </Card>
      </div>
    </div>
  );
}
