import React from 'react';
// Components
import UserDisconnectedDescription from '~/components/pageStructure/UserDisconnectedDescription';

export default function Addresses() {
  return (
    <UserDisconnectedDescription
      description="Nesta página você pode gerenciar suas requisições manuais, tanto para resgate de dinheiro como adicionar saldo Me."
    />
  );
}
