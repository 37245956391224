import React, { useEffect, useState } from 'react';
// Own Components
import Header from './components/Header';
import Content from './components/Content';
// Components
import Card from '~/components/card';
import Loading from '~/components/loading';
// Utils
import SocialMeAPI from '~/utils/SocialMeAPI';

export default function DonationsReceived() {
  const [donationsReceived, setDonationsReceived] = useState([]);
  const [loadingContent, setLoadingContent] = useState(true);

  useEffect(() => {
    async function GetDonationsReceived() {
      const params = {
        operation: ['Wallet', 'Accounts', 'Extract'],
        urlParams: {
          accountType: 'CS',
        },
        data: {
          pagination: {
            sort: {
              by: 'created_at',
              order: 'desc',
            },
          },
          filter: {
            income: true,
            //kind: [System.gateway.operations.internalDonationCCtoCS],
          },
        },
      };
      const response = await SocialMeAPI(params);
      console.log('  ⚡ API Response (Get DonationsReceived): ', response);
      if (response.success) {
        setDonationsReceived(response.body.detailedBalance.elements);
        setLoadingContent(false);
      }
    }
    GetDonationsReceived();
  }, []);

  if (loadingContent) {
    return <Loading text="Carregando suas requisições" />;
  }

  return (
    <div>
      <Card>
        <Header />
        <Content data={donationsReceived} />
      </Card>
    </div>
  );
}
